<template>
    <div>
        <Nav />
        <BreadCrumb />
        <div class="center land-box">
            <GroupSelector/>
            <div class="info-container">
                <el-row :gutter="18" v-for="(r, index) in landDataNested" :key="index">
                    <el-col :span="6" v-for="(o, index) in r" :key="index">
                        <!-- <ListingLandItemCard :supply-info="o" /> -->
                        <LandItemCard :landInfo="o" />
                    </el-col>
                </el-row>
            </div>
        </div>
        <footer2 />
    </div>
</template>

<script>
import Nav from '@/components/nav'
import GroupSelector from '@/components/GroupSelector'
import footer2 from "components/footer.vue";
import CommonService from "@/service/CommonService";
import {useSearchStore} from '@/stores/search';

export default {
    name: 'transfer',
    components: {
        Nav,
        footer2,
        GroupSelector
    },

    data() {
        return {
            searchStore:useSearchStore(),
            landData: [
            ]
        }
    },
    computed: {
        //array to nested array,4 items per row
        landDataNested() {
            return this.landData.reduce((acc, cur, i) => {
                if (i % 4 === 0) {
                    acc.push([cur]);
                } else {
                    acc[acc.length - 1].push(cur);
                }
                return acc;
            }, []);
        },
    },
    mounted() {
        this.init()
      Nav.data().navStore.$patch({activeIndex:1})
        this.searchStore.$subscribe((mutation, state) => {
            this.search(state);
        })
    },
    beforeDestroy(){
        this.searchStore.$reset();
    },
    methods: {
        init() {
            this.search(this.searchStore.params);
        },
        search(selector = {}) {
            var params = {
                regionId:selector.land_area,
                landType:selector.land_type,
                landPurpose:selector.land_purpose,
                transactionWay:selector.transaction_way,
                transactionStatus:selector.transaction_status,
                landSize:selector.land_size,
                landSupplyType:2,
                // land_dead_line
            }

            //获取土地列表
            CommonService.landSearch(params).then(res => {
                if (res.code === 0) {
                    this.landData = res.data.records;
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .info-container {
    .el-row {
        margin-bottom: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
